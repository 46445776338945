import { render, staticRenderFns } from "./sale-invoice-pos.vue?vue&type=template&id=62afacba&scoped=true&"
import script from "./sale-invoice-pos.vue?vue&type=script&lang=js&"
export * from "./sale-invoice-pos.vue?vue&type=script&lang=js&"
import style0 from "./sale-invoice-pos.vue?vue&type=style&index=0&id=62afacba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62afacba",
  null
  
)

export default component.exports